const optionsdata = [
    { value: "00", label: "0" },
    { value: "15", label: "15" },
    { value: "30", label: "30" },
    { value: "45", label: "45" },
  ];
  export default optionsdata;
  
  
  
  
  
const pref=[
    {'id':1,'name':'蛹玲ｵｷ・・'},
    {'id':2,'name':'・呈｣ｮ逵・'},
    {'id':3,'name':'蟯ｩ謇狗恁'},
    {'id':4,'name':'遘狗伐逵・'},
    {'id':5,'name':'螳ｮ蝓守恁'},
    {'id':6,'name':'螻ｱ蠖｢逵・'},
    {'id':7,'name':'遖?蟲ｶ逵・'},
    {'id':8,'name':'・ｱ莠ｬ驛ｽ'},
    {'id':9,'name':'逾槫･亥ｷ?逵・'},
    {'id':10,'name':'逾槫･亥ｷ?逵・'},
    {'id':11,'name':'蝓ｼ邇臥恁'},
    {'id':12,'name':'・・痩逵・'},
    {'id':13,'name':'闌ｨ蝓守恁'},
    {'id':14,'name':'譬・惠逵・'},
    {'id':15,'name':'鄒､鬥ｬ逵・'},
    {'id':16,'name':'譁ｰ貎溽恁'},
    {'id':17,'name':'蟇悟ｱｱ逵・'},
    {'id':18,'name':'遏ｳ蟾?逵・'},
    //
    {'id':19,'name':'遖?莠慕恁'},
    {'id':20,'name':'螻ｱ譴ｨ逵・'},
    {'id':21,'name':'髟ｷ驥守恁'},
    {'id':22,'name':'蟯?髦懃恁'},
    {'id':23,'name':'・吝ｲ｡逵・'},
    {'id':24,'name':'諢帷衍逵・'},
    {'id':25,'name':'荳蛾・逵・'},
    {'id':26,'name':'貊玖ｳ逵・'},
    {'id':27,'name':'莠ｬ驛ｽ蠎・'},
    {'id':28,'name':'螟ｧ髦ｪ蠎・'},
    {'id':29,'name':'蜈ｵ蠎ｫ逵・'},
    //
    {'id':30,'name':'螂郁憶逵・'},
    {'id':31,'name':'蜥梧ｭ悟ｱｱ逵・'},
    {'id':32,'name':'魑･・也恁'},
    {'id':33,'name':'蟲ｶ譬ｹ逵'},
    {'id':34,'name':'蟯｡螻ｱ逵・'},
    {'id':35,'name':'蠎・ｳｶ逵・'},
    //
    {'id':36,'name':'螻ｱ・｣逵・'},
    {'id':37,'name':'蠕ｳ蟲ｶ逵・'},
    {'id':38,'name':'鬥吝ｷ?逵・'},
    {'id':39,'name':'諢帛ｪ帷恁'},
    {'id':40,'name':'鬮倡衍逵・'},
    {'id':41,'name':'遖?蟯｡逵・'},
    {'id':42,'name':'菴?雉逵・'},
    {'id':43,'name':'髟ｷ蟠守恁'},
    {'id':44,'name':'辭頑悽逵・'},
    {'id':45,'name':'螟ｧ蛻・恁'},
    //
    {'id':46,'name':'螳ｮ蟠守恁'},
    {'id':47,'name':'鮖ｿ蜈?蟲ｶ逵・'},
    {'id':48,'name':'豐也ｸ・恁"'},


    

    
];

export default pref;
const fields = [
    'addition_of_book_mainCategory',
    'addition_of_book_subCategory',
    'addition_of_book_title',
    'addition_of_book_continuously',
    'addition_of_book_title_of_book',
    'addition_of_book_title',
    'addition_of_book_supervision',
    'addition_of_book_publisher',
    'addition_of_book_release_date',
    'addition_of_book_date_added',
    'addition_of_book_number_of_book',
    'addition_of_book_date_submission',
    'addition_of_book_date_content1',
    'addition_of_book_date_image',
    'addition_of_book_date_link',
  ];

  export default fields;
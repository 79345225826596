const occupation = [
  { label: 1, id: "会社員" },
  { label: 2, id: "公務員" },
  { label: 3, id: "学生" },
  { label: 4, id: "主婦" },
  { label: 5, id: "パート・アルバイト" },
  { label: 6, id: "自営業" },
  { label: 7, id: "無職" },
  { label: 8, id: "その他" },
];

export default occupation;

const year=[
    {id:1,label:"2016"},
    {id:2,label:"2017"},
    {id:3,label:"2018"},
    {id:4,label:"2019"},
    {id:5,label:"2020"},
    {id:6,label:"2021"},
    {id:7,label:"2022"},
    {id:8,label:"2023"} 

];

export default year;


